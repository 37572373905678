import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React, { ReactNode } from "react";
import './App.css';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { LoginState, setConnecting, setUser } from './redux/features/loginSlice';
import LoadingScreen from './components/LoadingScreen';
import LoginForm from './components/LoginForm';
import AppLayout from './components/AppLayout';
import * as ApiLink from './link/ApiLink';
import CacheBuster from 'react-cache-buster';
import { version } from './configs/appInfo';

function App() {
    const dispatch = useDispatch();
    const { connecting, user } = useSelector((state: { login: LoginState }) => state.login);

    React.useEffect(() => {
        ApiLink.getCurrentUser()
        .then(user => {
            if (user) {
                dispatch(setUser(user));
            }
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => {
            dispatch(setConnecting(false));
        }); 
    }, [dispatch]);

    function handleLoggedIn(user: ApiLink.User) {
        dispatch(setConnecting(false));
        dispatch(setUser(user));
    }

    const appParts: ReactNode[] = [];
    if (connecting || !user)
        appParts.push(<LoadingScreen key='loading-screen' connecting={connecting} />);
    console.log("connecting:", connecting, "user:", user);
    if (!connecting) {
        if (!user) {
            appParts.push(<LoginForm key='login-form' onLoggedIn={handleLoggedIn} />);
        } else {
            appParts.push(<AppLayout key='app-layout' user={user} />);
        }
    }

    const isProduction = process.env.NODE_ENV !== 'development';
    const metaFileDirectory = isProduction ? '.' : '/appcom';
    return (
        <CacheBuster
            currentVersion={version}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={true} //If true, the library writes verbose logs to console.
            loadingComponent={<LoadingScreen key='v-loading-screen' connecting={connecting} />} //If not pass
            metaFileDirectory={metaFileDirectory} //If public assets are hosted somewhere other than root on your server.
        >
            <div className="App">
                {appParts}
            </div>
        </CacheBuster>
    );
}

export default App;