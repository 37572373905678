import { GridValueFormatterParams } from "@mui/x-data-grid";

/**
 * Generates a color from an arbitrary string
 * @param string string to be transformed into color
 * @returns a `#rrggbb` hex color code
 */
export function stringToColor(string: string): string {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
}

/**
 * The definition for properties of an `<Avatar>` jsx element
 */
export type AvatarDef = {
    sx?: object,
    children?: string,
}

/**
 * Generate properties for an `<Avatar>` jsx element from user name
 * @param name a string representing the user's name
 * @returns 
 */
export function stringAvatar(name: string): AvatarDef {
    if (!name) {
        return {};
    }
    const spl = name.split(/\s+/);
    let letters = '';
    if (spl.length > 2) {
        letters = spl[0][0] + spl[1][0] + spl[2][0];
    }
    else if (spl.length > 1) {
        letters = spl[0][0] + spl[1][0];
    }
    else if (spl.length > 0) {
        letters = spl[0][0];
    }
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: letters,
    };
}

export const priceFormatter = new Intl.NumberFormat('fr-FR', { style : 'currency', currency: 'EUR' });

export const dateFormatter = new Intl.DateTimeFormat('fr-FR');

export const euroValueFormatter = (params: GridValueFormatterParams<any>) => {
    if (params.value == null) {
        return '';
    }

    return priceFormatter.format(params.value);
};

export const dateValueFormatter = (params: GridValueFormatterParams<any>) => {
    if (params.value == null) {
        return '';
    }

    const date = new Date(params.value);
    if (isNaN(date.getTime())) {
        console.warn("Invalid date", params.value);
        return '?';
    }

    return dateFormatter.format(date);
};