import { setUser } from "../redux/features/loginSlice";
import { store } from "../redux/store";

/**
 * Perform a GET request on the server API. Expects a JSON-formatted response
 * @param url URL to be queried
 * @param queryParms Request parameters to be passed in URL
 * @returns 
 */
export async function get(url: string, queryParms?: string | Record<string, string> | URLSearchParams | string[][] | undefined) : Promise<Response> {
    let queryString = '';
    if (queryParms) {
        queryString = '?' + new URLSearchParams(queryParms);
    }
    const resp = await fetch(url + queryString, {
        method: 'get',
        headers: {
            'Accept': 'application/json'
        },
        credentials: 'include'
    });

    await checkRespOk(url, resp);

    return resp;
}

/**
 * Perform a POST request on the server API. Expects a JSON-formatted response
 * @param url URL to be queried
 * @param body Object to be passed as request body, encoded into JSON
 * @returns 
 */
export async function post(url: string, body?: object) : Promise<Response> {
    const resp = await fetch(url, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        credentials: 'include'
    });

    await checkRespOk(url, resp);

    return resp;
}

async function checkRespOk(url: string, resp: Response) {
    if (!resp.ok) {
        switch (resp.status) {
            case 403:
                throw new AccessDeniedError(`Accès refusé par le serveur`);
            case 401:
                store.dispatch(setUser(undefined));
                throw new NotLoggedInError(`Accès refusé (utilisateur non connecté)`);
            case 404:
                throw new NotFoundError(`Non trouvé`);
        }
        //If other status and json is the custom json send from CI then Error
        try {
            //Getting the json resp for the error
            const errorJson = await resp.json();
            if (errorJson.error) {
                console.error(url, errorJson);
                let errorString: string = errorJson.error;
                if (errorJson.warnings && errorJson.warnings.length > 0) {
                    errorString += " : ";
                    let warningString = errorJson.warnings.join(' / ');
                    errorString += warningString;
                }
                throw new Error(errorString);
            } 
            else {
                console.error(url, errorJson.message);
                throw new Error(`Erreur de communication avec le serveur ! Code : ${errorJson.code} | Erreur : ${errorJson.message}`);
            }
        } catch (err) {
            if (err instanceof SyntaxError)
                console.error('response is probably not json', url, err);
            else
                throw err;
        }

        try {
            let b = await resp.text();
            console.error('response', resp.status, resp.statusText, 'body:', b);
            if (b) {
                if (b.length > 512)
                    b = b.substring(0, 511) + '…';
                throw new Error(`Erreur de communication avec le serveur ! Statut HTTP : ${resp.status} | Réponse :\n${b}`);
            }
        } catch (err) {
            if (err instanceof TypeError)
                console.error('response text not readable', url, err);
            else
                throw err;
        }
        throw new Error(`Erreur de communication avec le serveur ! Statut HTTP : ${resp.status} ${resp.statusText}`);
    }
}

export class AccessDeniedError extends Error {};
export class NotLoggedInError extends Error {};
export class NotFoundError extends Error {};
